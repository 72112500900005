var StoresLocatorUtils = {

    api: {
        loadStores: function (url, filters) {
            return new Promise(function (resolve, reject) {

                Object.keys(filters).forEach(function(key) {
                    url = url + '&' + key + '=' + filters[key];
                });

                $.ajax({
                    url: url,
                    data: filters,
                    dataType: 'json',
                    method: 'GET',
                    success: function (stores) {
                        resolve(stores);
                    },
                    error: function (xhr) {
                        reject(new Error(xhr));
                    }
                });
            });
        },

        getStoreTypes: function (url, allowedStoreTypes) {

            return new Promise(function (resolve, reject) {

                $.ajax({
                    url: url,
                    dataType: 'json',
                    method: 'GET',
                    success: function (storeTypes) {

                        if (allowedStoreTypes.length > 0) {
                            resolve(storeTypes.filter(function (t) {
                                return allowedStoreTypes.indexOf(t.key) >= 0;
                            }));

                        } else {
                            resolve(storeTypes);
                        }
                    },
                    error: function (xhr) {
                        reject(new Error(xhr));
                    }
                });
            });
        },

        getProductCategories: function (url, allowedCategories) {

            return new Promise(function (resolve, reject) {

                $.ajax({
                    url: url,
                    dataType: 'json',
                    method: 'GET',
                    success: function (categories) {

                        if (allowedCategories.length > 0) {
                            resolve(categories.filter(function (t) {
                                return allowedCategories.indexOf(t.key) >= 0;
                            }));

                        } else {
                            resolve(categories);
                        }
                    },
                    error: function (xhr) {
                        reject(new Error(xhr));
                    }
                });
            });
        },

        getAestheticCategories: function (url, allowedCategories) {

            return new Promise(function (resolve, reject) {

                $.ajax({
                    url: url,
                    dataType: 'json',
                    method: 'GET',
                    success: function (categories) {

                        if (allowedCategories.length > 0) {
                            resolve(categories.filter(function (t) {
                                return allowedCategories.indexOf(t) >= 0;
                            }));

                        } else {
                            resolve(categories);
                        }
                    },
                    error: function (xhr) {
                        reject(new Error(xhr));
                    }
                });
            });
        }
    },

    map: {

        metricSystem: "international",

        initMap: function ($map, settings) {
            return new Promise(function (resolve, reject) {

                var map = new google.maps.Map($map[0], settings);

                google.maps.event.addListenerOnce(map, 'idle', function () {
                    resolve(map);
                });
            });
        },

        getStoresMapSettings: function (options) {
            return {
                zoom: options.map.defaultZoom,
                disableDefaultUI: true,
                fullscreenControl: false,
                keyboardShortcuts: false,
                mapTypeControl: false,
                rotateControl: false,
                streetViewControl: false,
                zoomControl: false,
                center: {
                    lat: options.map.lat,
                    lng: options.map.lng
                },
                styles: [
                    {
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#212121"
                            }
                        ]
                    },
                    {
                        "elementType": "labels.icon",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#757575"
                            }
                        ]
                    },
                    {
                        "elementType": "labels.text.stroke",
                        "stylers": [
                            {
                                "color": "#212121"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#757575"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative.country",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#9e9e9e"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative.land_parcel",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "administrative.locality",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#bdbdbd"
                            }
                        ]
                    },
                    {
                        "featureType": "poi",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#757575"
                            }
                        ]
                    },
                    {
                        "featureType": "poi.park",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#181818"
                            }
                        ]
                    },
                    {
                        "featureType": "poi.park",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#616161"
                            }
                        ]
                    },
                    {
                        "featureType": "poi.park",
                        "elementType": "labels.text.stroke",
                        "stylers": [
                            {
                                "color": "#1b1b1b"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#2c2c2c"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#8a8a8a"
                            }
                        ]
                    },
                    {
                        "featureType": "road.arterial",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#373737"
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#3c3c3c"
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway.controlled_access",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#4e4e4e"
                            }
                        ]
                    },
                    {
                        "featureType": "road.local",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#616161"
                            }
                        ]
                    },
                    {
                        "featureType": "transit",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#757575"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#000000"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "color": "#3d3d3d"
                            }
                        ]
                    }
                ]
            }
        },

        averageGeolocation: function (markers) {

            var total = markers.length;
            if (total.length === 1) {
                return markers[0].position;
            }

            var x = 0.0;
            var y = 0.0;
            var z = 0.0;

            for (var i = 0; i < markers.length; i++) {
                var latitude = markers[i].position.lat() * Math.PI / 180;
                var longitude = markers[i].position.lng() * Math.PI / 180;

                x += Math.cos(latitude) * Math.cos(longitude);
                y += Math.cos(latitude) * Math.sin(longitude);
                z += Math.sin(latitude);
            }

            x = x / total;
            y = y / total;
            z = z / total;

            var centralLongitude = Math.atan2(y, x);
            var centralSquareRoot = Math.sqrt(x * x + y * y);
            var centralLatitude = Math.atan2(z, centralSquareRoot);

            return new google.maps.LatLng(centralLatitude * 180 / Math.PI, centralLongitude * 180 / Math.PI);
        },

        evaluateZoomByDistance: function (latLng, mapWidth, radius) {
            var radiusMt = radius * 1000;
            if (StoresLocatorUtils.map.metricSystem !== "international") {
                radiusMt *= 1.60934;
            }

            var k = mapWidth * 156543.03392 * Math.cos(latLng.lat * Math.PI / 180);
            var zoom = Math.round(Math.log((100 * k) / (radiusMt * 100)) / Math.LN2);
            return zoom - 1;
        },

        getGeoPositionOfPlace: function (place, locale) {
            return new Promise(function (resolve, reject) {

                $.ajax({
                    url: 'https://maps.googleapis.com/maps/api/geocode/json',
                    data: {
                        'address': place,
                        'key': mapsGeoCodingApiKey,
                        'language': locale.language,
                        'region': locale.region
                    },
                    dataType: 'json',
                    method: 'GET',
                    success: function (result) {
                        if (result.status === 'OK' && result.results.length > 0) {
                            resolve(result.results);

                        } else {
                            reject();
                        }
                    },
                    error: function (xhr) {
                        reject(new Error(xhr));
                    }
                });
            });
        }
    }
};
